.product-table{
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.008), 0 1.23px 4px rgba(0, 0, 0, 0.008) !important;
    border-radius: 23px !important;
    
}

.product-table-heading{
    font-size: 16px !important;
    color: rgba(1, 54, 91, 0.88) !important;
    font-weight: 700 !important;
    border: none !important;
    font-family: Montserrat !important;
}

.table-cell{
    color: #01365B !important;
    font-family: Montserrat !important;
}

.dense-padding-flex{
    display: flex !important;
    justify-content: flex-end !important;
    
}

.dense-padding{
 font-family: Montserrat !important;
 font-size: 0.88rem !important;
 font-weight: 400 !important;
}

.table-subheading{
   font-size: 14px !important;
   color: #70839C !important;
   font-weight: 700 !important;
   border: none !important;
   font-family: Montserrat !important;
   margin-left: 1.23rem !important;
}

.table-subheading-mini{
    font-size: 14px !important;
    color: #70839C !important;
    font-weight: 700 !important;
    border: none !important;
    font-family: Montserrat !important;
 }

.table-subheading-border{
     font-size: 14px !important;
    color: #70839C !important;
    font-weight: 700 !important;
    border-bottom: solid 1px #70839C !important;
    font-family: Montserrat !important;
}

.table-pagenation{
    font-weight: 900 !important;
}


