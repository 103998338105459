.display-title {
  font-size: 2.23rem;
  margin: 0 0 0.88rem 0;
  color: #00365a;
}
@media (max-width: 767px) {
  .display-title h1 {
    font-size: 2rem;
  }
}

.breadcrumbs {
  font-size: 16px;
}

.breadcrumb-link:hover {
  cursor: pointer;
}

.modalContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 998;
}

.escapeModal {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 450px;
  width: 700px;
  background-color: white;
  z-index: 999;
  top: calc(50vh - 225px);
  left: calc(50vw - 350px);
  border-radius: 23px;
}

.modalHead,
.modalBody,
.modalFoot {
  flex: 1;
  padding: 50px;
}

.modalHead,
.modalBody {
  border-bottom: 1px solid rgb(218, 218, 218);
}

.modalHead {
  display: flex;
  font-size: 2.23rem;
}

.modalTitle {
  flex: 1;
}

/* .modalClose {
  
} */

.modalBody {
  font-size: 16px;
}

/* .modalFoot{
  padding: 50px;
  flex: 1;
} */

.modalButtons {
  float: right;
}

.modalButtonCancel {
  margin-right: 10px !important;
}

@media only screen and (max-width: 768px) {
  .escapeModal {
    width: 300px;
    height: 250px;

    left: calc(50vw - 150px);
    top: calc(50vh - 125px);
  }

  .modalHead,
  .modalBody,
  .modalFoot {
    flex: 1;
    padding: 10px;
  }

  .modalHead {
    font-size: 1.7rem;
  }
}
