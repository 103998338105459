.table-restrict{
   width: 100% !important;
   /* left: 130px !important; */
   margin-bottom: 1.23rem;
}

.table-cell-test{
    padding: 0 !important;
    margin: 0 !important;
}
