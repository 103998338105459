.horizontal-flex-center{
    display: flex;
    width: 100%;
  
}

.button-box{
    background: white;
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.012), 0 1.23px 4px rgba(0, 0, 0, 0.12);
    padding: 1.23rem;
    border-radius: 23px;
    border: none;
    color: #01365B;
    margin-bottom: 0.5rem;
}

.button-box:hover{
    background-color: rgba(12, 113, 195, 0.12);
}

.large-icon{
    width: 8.8rem !important;
    height: 8.8rem !important;
    border-radius: 2.3rem !important;
    color: #01365B !important;
}

.small-icon{
    flex: 1;
    height: 4.4rem !important;
    width: 4.4rem !important;
}