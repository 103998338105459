.line-chart{
    padding:  0.23rem 1.23rem;
    box-shadow: 6px 10px 20px rgba(0,0,0,0.02);
    border-radius: 23px;
    font-size: 12px;
    background-color: white;
}

.chart-title{
    font-size: 1.23rem;
    margin: 1.23rem 0 1.23rem 0;
}