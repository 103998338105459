.hamburger-menu {
  position: fixed;
  top: 88px;
  margin: auto;
  /* height: calc(100vh - 88px); */
  transition: height 0.5s;
  z-index: 10;
}

.hm-item {
  width: calc(100% - 0.46rem);
  height: calc((100vh - 88px) / 6);
  background-color: white;
  /* text-align: center; */
  color: #01365b;
  padding-top: calc((((100vh - 148px) / 6) / 2) - 33px);
  border-radius: 5px;
}

.hm-item-content{
  display: flex;
  /* width: 155px; */
  /* flex-direction: column; */
}

.hm-item-text{
  font-size: 22px;
  padding-top: 2px;
  /* padding-left: calc(50vw - 116px); */
  flex: 1;
  font-weight: bold;
}

.hm-item-icon{
  padding-left: 32px;
  padding-right: 50px;
}

.logout-button:hover{
  cursor: pointer;
  flex: 1;
}

