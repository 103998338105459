.product-stepper-container {
  background: rgba(12, 113, 195, 0.23);
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.012), 0 1.23px 4px rgba(0, 0, 0, 0.04);
  border-radius: 23px;
  padding-left: 1.23rem;
  padding-right: 1.23rem;
}

.add-product-button-faded {
  width: 10.23rem;
  height: 2.23rem;
  font-size: 14px;
  border-radius: 44px;
  border-style: none;
  background-color: rgba(1, 54, 91, 0.55);
  color: white;
  cursor: pointer;
  font-family: Montserrat;
  font-weight: 500;
}

.large-form-container {
  width: 100%;
  height: auto;
  margin-bottom: 2.23rem;
  background: rgba(12, 113, 195, 0.23);
  border-radius: 23px;
  padding: 2.23rem 2.23rem 4.23rem 2.23rem;
}

.form-spacing-top {
  margin-bottom: 1.23rem;
}

.form-spacing {
  margin: 1.23rem 0;
}

.form-horizontal-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.form-left-flex {
  width: 100%;
  flex: 1.55;
  margin-right: 1.23rem;
}

.form-right-flex {
  width: 100%;
  flex: 1;
}

.button-bottom-flex {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.save-button-flex {
  display: flex;
  justify-content: space-between;
}

.form-header-flex {
  width: 100%;
  flex: 2;
}

.button-flex {
  width: 100%;
  flex: 1;
}

@media only screen and (max-width: 1023px) {
  .select-title-flex,
  .form-horizontal-flex,
  .save-button-flex,
  .form-elements-flex {
    flex-direction: column;
  }
  .hero-select-style {
    width: 50%;
  }
}
