.thanks-flex{
    height: 23rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.colour-button-flex{
    display: flex;
    flex-direction: column;
}

.important-info{
    margin-top: 2.23rem;
    font-size: 14px;
    font-weight: 400;
    color: rgba(1, 54, 91, 0.55);
}

.business-logo{
    width: 7.7rem;
    height: auto;
    /* border-radius: 2.3rem; */
}

.input-style-display{
    width: 100%;
    margin-bottom: 1.23rem ;
    height: 3.3rem;
    border-style: none;
    background-color: #ebf1f5a6;
    border-radius: 5px;
    padding: 1.23rem;
    font-size: 18px;
    color: #0c71c3ce;
}

.input-para-style-display{
    width: 100%;
    margin-bottom: 0.55rem ;
    height: 7.7rem;
    border-style: none;
    background-color: #ebf1f5a6;
    border-radius: 5px;
    padding: 0.88rem 1.23rem;
    font-size: 18px;
    color: #0c71c3ce;
    resize: none;
    /* overflow: hidden; */
}

.input-style-display-left{
    width: 100%;
    margin-bottom: 1.23rem ;
    height: 3.3rem;
    border-style: none;
    background-color: #ebf1f5a6;
    border-radius: 5px;
    padding: 1.23rem;
    font-size: 18px;
    color: #0c71c3ce;
    margin-right: 1.23rem;
    
}

.error{
    background-color: #ffddd6;
}