.login-body{
    height: 100vh;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    font-size: 12px;
}


.login-page{
    height: 100vh;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 12px;
}
