
.modalContainer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 998;
  }
  
  .escapeModal {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 250px;
    width: 350px;
    background-color: white;
    z-index: 999;
    top: calc(50vh - 125px);
    left: calc(50vw - 175px);
    border-radius: 23px;
  }
  
  .modalHead,
  .modalBody,
  .modalFoot {
    flex: 1;
    padding: 20px;
  }
  
  .modalHead,
  .modalBody {
    border-bottom: 1px solid rgb(218, 218, 218);
  }
  
  .modalHead {
    display: flex;
    
  }
  
  .modalTitle {
    flex: 1;
    font-size: 1rem;
    padding-top: 20px;
    font-weight: bold;
  }

  .modalClose:hover {
    cursor: pointer;
  }
  
  /* .modalClose {
    
  } */
  
  .modalBody {
    font-size: 0.88rem;
  }
  
  /* .modalFoot{
    padding: 50px;
    flex: 1;
  } */
  
  .modalButtons {
    float: right;
  }
  
  .modalButtonCancel {
    margin-right: 10px !important;
  }

  .modalButtonLeave{
    background-color: #c62828 !important;
    color: #fff;
  }
  
  @media only screen and (max-width: 768px) {
    .modalTitle{
      padding-top: 10px;
    }
  }
  