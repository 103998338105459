.main-container{
  width: 100%;
}

.topnav-container{
  width:100%;
  height: 88px;
  position: sticky;
  top:0;
  z-index: 999;
}

.home-page{
  display: flex;
  overflow-x: auto;
}

.sidebar-container{
  /* height: 100%; */
  width: 130px;
  z-index: 0;
  /* position: sticky; */
  left:0;
  flex-shrink: 0;
}


.app-body{
  position: relative;
  width: 100%;
  /* left: 130px !important; */
  padding: 1.23rem 3.23rem 0 3.23rem;
  font-family: Montserrat;
  margin-bottom: 5.5rem;
  z-index: 0;
  overflow-x: hidden;
}

.table-search-container{
  flex: 1;
  margin-left: 20px;
}

@media only screen and (max-width: 1023px) {
  .large-screen-only{
    display: none !important
  }

  .app-body {
    padding: 20px 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .small-screen-only{
    display: none !important;
  }

}


