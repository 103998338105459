.small-widget{
   width:100%;
    display: flex;
    flex-direction: column;
    padding: 1.23rem;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.008), 0 1.23px 4px rgba(0, 0, 0, 0.008);
    border-radius: 23px;
}

.small-widget-title{
    font-size: 1.23rem;
}
.small-widget-company-name{
    font-size: 16px;
}

.small-widget-list{
    margin: 0;
    display: block;
    list-style-type: disc;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}

.small-widget-list-item{
    display: flex;
    align-items: center;
  
}

.logo-title-flex{
    display: flex;
    width: auto;
    align-items: center;
    margin-right: 1.23rem;
}

.small-widget-image{
    width: 3.3rem;
    height:3.3rem;  
    border-radius: 0.23rem;
}

.small-widget-text{
    width: 100%;
    padding: 1.23rem 1.23rem 1.23rem 0.23rem;
    font-size: 18px;
}

.small-widget-button-group{
    padding-right: 0rem;
}

.complete-icon {
    margin: 0.2rem;
    color: #0c71c3;
}

.waiting-icon {
    margin: 0.2rem;
    color: lightgray;
}

.error-icon {
    margin: 0.2rem;
    color: rgb(212, 14, 14);
}