

.small-colour .react-colorful {
    border-radius: 12px;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.008), 0 1.23px 4px rgba(0, 0, 0, 0.008);
    display: flex;
    flex-direction: column;
}

.responsive-colour{
    width: auto;
}  

.small-colour .react-colorful_hue-pointer{
    width: 12px;
    height: inherit;
    border-radius: 0;
}

.colour-input-container{
    width: 100%;
}

.colour-input{
    border-style: none;
    background-color: #fff;
    font-size: 14px;
    color: #0C71C3;
    text-align: left;
}

.close-container{
    width:80%;
    display: flex;
    align-items: right;
    justify-content: space-between;
    flex-direction: row-reverse;
}