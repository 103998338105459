.top-nav {
  width: 100%;
  background-color: white;
  height: 88px;
  padding: 1.23rem 0.23rem;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.008), 0 1.23px 4px rgba(0, 0, 0, 0.008);
  position: sticky;
  top: 0;
  z-index: 500;
}
.top-nav-wrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.55rem;
}

.logo-container {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-left {
  display: flex;
  flex: 1;
}

.top-middle {
  flex: 0.1;
}

.top-right {
  flex: 1;
}

.mobile-logo {
  width: 55px;
  height: 55px;
  object-fit: contain;
}

.searchbar-container {
  flex: 1;
}

.searchbar-container > .search-container {
  width: 70%;
}

@media only screen and (max-width: 1023px) {
  .searchbar-container {
    display: none !important;
  }

  .right-icon-container {
    display: none !important;
  }
}

.full-searchbar {
  position: absolute;
  width: 100%;
  z-index: 999;
}

.full-searchbar > .search-container {
  flex: 1;
}

.right-icon-container {
  float: right;
}
