.large-widget{
    width:100%;
    margin-left: 1.23rem;
    padding: 1.23rem;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.008), 0 1.23px 4px rgba(0, 0, 0, 0.008);
    border-radius: 23px;
}

.large-widget-title{
    font-size: 1.23rem;
    margin-bottom: 2.23rem;
}

.large-widget-button-container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.23rem;
}

.large-widget-product-container{
    width:70%;
    display: flex;
    justify-content: space-between;
}

.large-widget-image{
    width: 8.8rem;
    height:5.5rem;
    border-radius: 23px;
}

.widget-text-flex{
    display: flex;
    flex-direction: column;
    height: 2rem;
}

.large-widget-product-name{
    font-size: 16px;
    margin: 0 0 0.88rem 0;
}

.large-widget-product-sub{
    font-size: 14px;
    margin:0 0 0.88rem 0;
}

.large-widget-product-text{
    font-size: 12px;
    margin:0 0 0.88rem 0;
}

.large-widget-button-text-vertical{
    display: flex;
    flex-direction: column;
    
}

.view-button-text{
    font-size:12px;
}

.widget-large-button{
padding-right: 2.3rem;
}