.brand-type-icons{
    display: flex;
    width:100%;
    margin-bottom: 0.88rem;
}


.input-vert-flex{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.icon-title-flex-left{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1.23rem;
}

.input-style-para{
    width: 100%;
    margin-bottom: 0.55rem ;
    height: 7.7rem;
    border-style: none;
    background-color: #F4F4F4;
    border-radius: 5px;
    padding: 0.88rem 1.23rem;
    font-size: 14px;
    color: #000;
    overflow: hidden;
}

.input-style-para::placeholder{
    color: rgba(1, 54, 91, 0.55);
    font-size: 18px;
}

.colour-title{
    font-size: 0.88rem;
    margin-bottom: 1.23rem;
}

.colour-pickers-flex{
    width: 100%;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 516px) {
    .colour-pickers-flex {
        flex-direction: column;
        align-items: normal;
    }
}