.form-items {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.88rem;

  border-radius: 5px;
  border: 1px solid rgba(0,0,0,0.1);
  /* box-shadow: 0 1px 15px rgba(0, 0, 0, 0.112), 0 1.23px 4px rgba(0, 0, 0, 0.04); */
  margin-bottom: 2.23rem;
  background-color: rgba(255, 255, 255, 0.23);
}

.form-column-flex {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select-title-flex {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-mini-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0.23rem;
  padding-top: 10px;
}

.form-elements-flex {
  display: flex;
  justify-content: space-between;
}

.select-left {
  width: 100%;
  margin-bottom: 1.23rem;
  height: 2.3rem;
  border-style: none;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding: 0 0.23rem;
  font-size: 16px;
  color: rgba(1, 54, 91, 0.55);
  margin-right: 1.23rem;
}

.form-select-style {
  width: 100%;
  margin-bottom: 1.23rem;
  height: 2.3rem;
  border-style: none;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding: 0 0.23rem;
  font-size: 16px;
  color: rgba(1, 54, 91, 0.55);
  border: 1px solid #D4d4d4;
}

.form-select-style-margin {
  width: 100%;
  margin: 0 1.23rem 1.23rem 0;
  height: 2.3rem;
  border-style: none;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding: 0 0.23rem;
  font-size: 16px;
  color: rgba(1, 54, 91, 0.55);
}

.form-element-style {
  width: 100%;
  margin-bottom: 0rem;
  height: 2.3rem;
  border-style: none;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding: 0.88rem 0.23rem;
  font-size: 16px;
  color: rgba(1, 54, 91, 0.55);
  border: 1px solid #D4d4d4;
}

.form-element-style-margin {
  width: 100%;
  margin: 0 1.23rem 1.23rem 0;
  height: 2.3rem;
  border-style: none;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding: 0.88rem 0.23rem;
  font-size: 16px;
  color: rgba(1, 54, 91, 0.55);
}

.form-element-style::placeholder {
  color: rgba(1, 54, 91, 0.55);
}

.form-element-style-margin::placeholder {
  color: rgba(1, 54, 91, 0.55);
}

.left-elements {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  margin-right: 0.88rem;
}

.right-elements {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-direction: column;
}

.hero-select-style {
  width: 23%;
  margin-bottom: 1.23rem;
  height: 2.3rem;
  border-style: none;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding: 0 0.23rem;
  font-size: 18px;
  color: rgba(1, 54, 91, 0.55);
}

.form-element-description {
  width: 100%;
  margin-bottom: 1.23rem;
  height: 4.4rem;
  border-style: none;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding: 0.28rem 0.23rem;
  font-size: 16px;
  border: 1px solid #D4d4d4;
  color: rgba(1, 54, 91, 0.55);
}

.form-element-notes {
  width: 100%;
  margin-bottom: 1.23rem;
  height: 4.4rem;
  border-style: none;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding: 0.28rem 0.23rem;
  font-size: 16px;
  border: 1px solid #D4d4d4;
  color: rgba(1, 54, 91, 0.55);
}

.checkbox {
  background-color: white;
  border-radius: 12%;
  margin-right: 1.23rem;
  border: 1px solid #ddd;
  cursor: pointer;
}

.mini-horizontal-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.mini-element-margin {
  width: 100%;
  margin: 0 0.55rem 1.23rem 0;
  height: 2.3rem;
  border-style: none;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding: 0 0.23rem;
  font-size: 12px;
  color: rgba(1, 54, 91, 0.55);
}

.mini-element-comission {
  width: 100%;
  margin: 0 0.55rem 1.23rem 0;
  height: 2.3rem;
  border-style: none;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding: 0 0.23rem;
  font-size: 16px;
  color: rgba(1, 54, 91, 0.55);
}

.mini-display-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 88%;
  margin: 0 0.55rem 1.23rem 0;
  height: 2.3rem;
  border-style: none;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding: 0 0.23rem;
  font-size: 12px;
  color: rgba(1, 54, 91, 0.55);
}

.mini-box-text {
  margin-top: 0.88rem;
  width: 100%;
  font-size: 16px;
  color: rgba(1, 54, 91, 0.55);
  font-weight: 400;
}

.mini-element {
  width: 100%;
  margin-bottom: 1.23rem;
  height: 2.3rem;
  border-style: none;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding: 0 0.23rem;
  font-size: 12px;
  color: rgba(1, 54, 91, 0.55);
}

.mini-element::placeholder {
  color: rgba(1, 54, 91, 0.55);
}

.mini-element-margin::placeholder {
  color: rgba(1, 54, 91, 0.55);
}

.mini-heading {
  width: 100%;
  font-size: 14px;
}

.midi-heading {
  width: 100%;
  font-size: 14px;
}

.mini-form-element-style {
  width: 50%;
  margin-bottom: 1.23rem;
  height: 2.3rem;
  border-style: none;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding: 0 0.23rem;
  font-size: 14px;
  color: rgba(1, 54, 91, 0.55);
}

.mini-form-element-style::placeholder {
  color: rgba(1, 54, 91, 0.55);
}

.mini-heading-flex {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mini-body {
  font-size: 12px;
}

.grid-icon-flex {
  width: 50%;
  display: flex;
  margin-bottom: 0.23rem;
}

.select-file-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.55rem;
}

.important-info-grey {
  font-size: 14px;
  font-weight: 400;
  color: rgba(1, 54, 91, 0.55);
}

.mini-left-elements {
  width: 80%;
  flex: 1;
  display: flex;
  margin-right: 1.23rem;
}

.mini-right-elements {
  width: 100%;
  display: flex;
  flex: 2;
}

.input-adornment {
  display: flex;
  align-items: center;
}

.adornment-text {
  font-size: 12px;
  width: auto;
  margin: 0 0.23rem 1.23rem 0;
  height: 2.3rem;
  border-style: none;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding: 0.55rem;
  font-size: 14px;
  color: rgba(1, 54, 91, 0.55);
  font-weight: 400;
}

.form-underline-flex {
  margin-top: 0.88rem;
  margin-bottom: 1.23rem;
  display: flex;
  width: 100%;
  height: 0.1rem;
  background: rgba(1, 54, 91, 0.08);
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.008), 0 1.23px 4px rgba(0, 0, 0, 0.008);
  border-radius: 44px;
}

.colour-name {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  padding-left: 0.5rem;
  padding-top: 0.1rem;
  height: 1.5rem;
  border-radius: 44px;
  font-size: small;
  width: fit-content;
}

.imported-image {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
}

.brandIcon > div > .form-mini-title > a > .imported-image {
  width: 250px;
  height: 50px;
}

.basinIconBackground{
  width: 70px;
  height: 35px;
  background-color: #C4C4C4;
}

.basinCheckbox{
  margin-top: 7px;
  margin-right: 10px;
  width: 21px;
  height: 21px;
}

.basinIconInner{
  width: 21px;
  height: 17px;
  border-radius: 5px;
  margin-top: 8px;
  background-color: #70839C;
  display: inline-block;
}

@media only screen and (max-width: 1140px) {
  .basinPrices >.adornment-text{
    display: none;
  }
}
