.large-card-container {
  flex: 1;
  height: 100%;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.008), 0 1.23px 4px rgba(0, 0, 0, 0.008);
  border-radius: 23px;
  padding: 1.23rem;
  margin: 0;
  background-color: white;
  height: 100%;
}

.edit-card-container {
  background: rgba(12, 113, 195, 0.23);
  width: 23rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.008), 0 1.23px 4px rgba(0, 0, 0, 0.008);
  border-radius: 23px;
  padding: 1.23rem;
  margin: 0;
}

.mini-card-container {
  width: 100%;
  height: 13.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.008), 0 1.23px 4px rgba(0, 0, 0, 0.008);
  border-radius: 23px;
  padding: 1.23rem;
  margin: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  background-color: white;
}

.edit-button-flex {
  width: 100%;
  margin: 0;
  display: flex;
  padding: 0;
}

.card-logo-container {
  width: 100%;
  display: flex;
  border-radius: 12px;
  flex: 8;
}

.button-container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.card-image {
  width: 5.5rem;
  height: 5.5rem;
  object-fit: contain ;
  border-radius: 12px;
}

.mini-card-image {
  width: 5.5rem;
  height: 5.5rem;
}

.card-group-flex {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.23rem;
  justify-content: space-between;
}

.card-title {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  color: rgba(1, 54, 91, 0.55);
  margin-bottom: 0.55rem;
}

.mini-card-title {
  font-size: 16px;
  font-weight: 700;
  color: rgba(1, 54, 91, 0.88);
  margin: 1.23rem 0 0.55rem 0;
}

.card-body {
  width: 100%;
  font-size: 14px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  word-wrap: break-word;
}

.card-mini-horizontal-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.55rem;
}

.card-mini-title {
  flex: 1;
  font-size: 14px;
}

.card-mini-body {
  flex: 2;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.social-icons-flex {
  width: 100%;
  display: flex;
  gap: 10px;
}

.social-icons-flex a {
  color: #00365A;
}

.mini-card-counter {
  font-size: 33px;
  font-weight: 700;
  margin: 0;
}

.mini-cards-flex {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  overflow: auto;
}

/* @media only screen and (max-width: 1120px) {
    .card-container{
        width: 100%;
    }
} */
