.featured-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
   
}

.featured-title{
    display: flex;
    font-size: 16px;
    font-weight: 500;
    justify-content: space-between;
}

.featured-item{
    height: 8.8rem;
    width: 23rem;
    font-size: 23px;
    padding: 2.23rem;
    cursor: pointer;
    box-shadow: 0 1.23px 15px rgba(0, 0, 0, 0.023), 0 1.23px 4px rgba(0, 0, 0, 0.03);
    border-radius: 23px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
}

.featured-money-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 23px;
}

.featured-subtitle{ 
    font-size: 12px;  
}
