.quick-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}


.underline-flex {
  margin-top: 0.88rem;
  display: flex;
  width: 100%;
  height: 0.1rem;
  background: rgba(1, 54, 91, 0.08);
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.012), 0 1.23px 4px rgba(0, 0, 0, 0.04);
  border-radius: 44px;
}
.home-widgets {
  width: 100%;
  display: flex;
  margin-top: 1.0rem;
  justify-content: space-between;
  overflow: hidden;
}

.mini-home-widgets {
  width: calc(100% - 40vw);
  padding: 10px;
  padding-top: 0;
  flex: 1;
  /* display: flex;
  overflow: auto;
  min-width: 35.2rem;
  flex-direction: column; */
}



.horizontal-flex {
  margin: 2.23rem 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.home-lower-body{
  display: flex;
  margin-top: 2.23rem
}


.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-top: 1.3rem;
  gap: 20px;
}

.dashboard-grid-left { 
  min-width: 25rem;
}

.dashboard-grid-right {

}

.dashboard-vertical-flex{
  display: 'flex';
  flex-direction: 'column';
  gap:'10px';
  height: 100%;
}

.analytics-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding:  0.23rem 1.23rem;
  box-shadow: 6px 10px 20px rgba(0,0,0,0.02);
  border-radius: 23px;
  font-size: 12px;
  background-color: white;
  margin-top: 10px;
  gap: 20px;
}

.analytics-card {
  border-radius: 23px;
  margin: 10px 0;
  text-align: center;
  background-color: #fcfcfc;
  padding: 20px 0;
  border: 0.5px solid #f0f0f0;
}

.analytics-card a {
  color: #00365A;
}

.analytics-card h2 {
  text-align: center;
  font-size: 25px;
  margin-top: 10px;
}

.analytics-card p {
  font-size: 30px;
  font-weight: bold;
}

.analytics-card .MuiSvgIcon-root {
  font-size: 40px;
}

@media only screen and (max-width: 1024px) {
  .home-widgets{
    flex-direction: column;
  }

  .mini-home-widgets{
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .analytics-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 1024px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
  }

  .dashboard-grid-left {
    min-width: 0;
    width: 100%;
  }

}

@media only screen and (max-width: 469px) {
  .analytics-container {
    grid-template-columns: 1fr;
  }
}