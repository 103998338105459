.login-page {
  width: 100%;
  display: flex;
  align-items: center;
}

.login-form-background {
  width: 100%;
  height: 100%;
  background-color: rgba(20, 83, 136, 0.23);
  display: flex;
  justify-content: center;
}

.login-form {
  margin: 0 auto;
  display: flex;
  width: 40%;
  margin: 4.4rem;
  padding: 1.23rem;
}

.magic-in-the-details {
  flex: 1;
  background-color: rgba(20, 83, 136, 0.99);
  border-radius: 23px 0 0 23px;
  color: white;
  font-size: 33px;
  font-weight: 700;
  padding-left: 1.23rem;
  padding-top: 0.55rem;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1.5;
  background-color: rgba(255, 255, 255, 0.99);
  border-radius: 23px;
  padding: 1.23rem 2.23rem;
  font-size: 8px;

}

.title-button-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-elements {
  padding: 1.23rem 0.23rem;
}

.magic-title {
  margin-top: 1.23rem;
  font-weight: 700;
  font-size: 33px;
  font-family: "Montserrat";
}

.magic-subheading {
  font-weight: 500;
  font-size: 16px;
  font-family: "Montserrat";
  margin-top: -0.88rem;
}

.magic-body {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.23rem;
}

.white-full-login-logo {
  width: 44%;
  margin: 0 auto;
}

.form-title {
  font-size: 33px;
  font-weight: 400;
  margin-bottom: 2.23rem;
}

.form-input-style {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form-input {
  margin-bottom: 1.23rem;
  height: 3.3rem;
  width: 100%;
  border-style: none;
  background-color: #f3faff;
  border-radius: 5px;
  font-size: 18px;
  padding-left: 1.23rem;
}

.short-input-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.short-input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.short-input {
  margin-bottom: 1.23rem;
  height: 3.3rem;
  width: 90%;
  border-style: none;
  background-color: #f3faff;
  border-radius: 5px;
  padding-left: 1.23rem;
  font-size: 18px;
}

.short-input-ln {
  margin-bottom: 1.23rem;
  height: 3.3rem;
  width: 100%;
  border-style: none;
  background-color: #f3faff;
  border-radius: 5px;
  padding-left: 1.23rem;
  font-size: 18px;
}

.error {
  background-color: #ffddd6;
}

.form-label {
  font-size: 14px;
  margin-bottom: 0.23rem;
}

.button-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link-button {
  border: none;
  background-color: white;
  font-family: Montserrat;
  cursor: pointer;
  color: #01365b;
  font-size: 14px;
}

.form-button {
  width: 8.8rem;
  height: 3.3rem;
  font-size: 18px;
  border-radius: 44px;
  margin: 1.23rem 0;
  border-style: none;
  background-color: #0c71c3;
  font-weight: 700;
  color: white;
  cursor: pointer;
  font-family: Montserrat;
  font-weight: 700;
}

.form-button-create-account {
  width: 12.3rem;
  height: 3.3rem;
  font-size: 18px;
  border-radius: 44px;
  margin: 1.23rem 0;
  border-style: none;
  background-color: #0c71c3;
  font-weight: 700;
  color: white;
  cursor: pointer;
  font-family: Montserrat;
  font-weight: 500;
}

/* button:disabled{
    background-color: gray;
} */

.form-button:disabled {
  background-color: gray;
}

.link-button:disabled {
  color: gray;
  background-color: white;
}

@media only screen and (max-width: 1024px) {
  .magic-in-the-details {
    display: none;
  }

  .form-container {
    
    max-height: 99vh;
    overflow: auto;
    min-width: 80vw;
  }

  .button-group {
    flex-direction: column;
  }

  .title-button-flex {
    flex-direction: column;
  }

  .create-account-button {
    position: relative;
    top: 330px;
  }

  .login-form{
    width: 90%;
  }
}
