.sidebar {
  height: 100%;
  position: fixed;
  top: 88px;
  width: 123px;
  padding: 0.23rem 0.23rem 0.23rem 0.55rem;
  color: #01365b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background-color: white;
  transition: margin-left 0.5s;
  overflow: auto;
  box-shadow: 6px 10px 20px rgba(0,0,0,0.02);
}

.sidebar-menu {
  padding-top: 1.23rem;
  display: flex;
  flex-direction: column;
}

.sidebar-list li {
  margin: 44px 0 44px 0;
  font-size: 12.3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 66px;
  padding-right: 55%;
}

.sidebar-item:hover {
  color: #0c71c3;
}

.links {
  color: #01365b;
  text-decoration: none;
}


.sidebar-minimize {
  padding: 0;
  margin-left: -123px;
}
