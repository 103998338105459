body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 23px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #00365a;
  background-color: #f8f8f8;
}
 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar-icon {
  cursor: pointer;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mini-card-container{
  margin-bottom: 2px;
}