.products-body{
    flex: 4;
    margin-left: 123px;
    padding:  1.23rem;
    display: flex;
    flex-direction: column;    
}

.filter-bar-container{
    width:100%;
    margin-bottom: 1.23rem;
}