.add-company-form-container{
    background: rgba(12, 113, 195, 0.23);
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.008), 0 1.23px 4px rgba(0, 0, 0, 0.008);
    border-radius: 23px;
    padding-left: 1.23rem;
    margin-bottom: 2.23rem;
    }

.button-title-flex{
    display:flex;
    justify-content: space-between;
    align-items:baseline;
}

.add-company-form-title{
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 1.23rem;
}

.button-flex{
    width:100%;
    display: flex;
    align-items: right;
    justify-content: space-between;
    flex-direction: row-reverse;
    
}

.button-div{
    display: flex;
    justify-content: flex-end;
    padding-right:2.23rem;
}

.form-button-submit{
    width: 8.8rem;
    height: 3.3rem;
    font-size: 18px;
    border-radius: 44px;
    border-style: none;
    background-color: #01365B;
    color: white;
    cursor: pointer;
    font-family: Montserrat;
    font-weight: 500;
}

.error{
    background-color: #fedfff;
}

button:disabled{
    background-color: gray;
}

.company-page-else{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

h5 {
    margin: 0;
    font-size: 16px;
    color: #01365B;
    font-weight: 700;
}