
.add-company-button{
    width: 10.23rem;
    height: 2.23rem;
    font-size: 14px;
    border-radius: 44px;
    border-style: none;
    background-color: #01365B;
    color: white;
    cursor: pointer;
    font-family: Montserrat;
    font-weight: 500;
}

.add-company-button-right{
    width: 10.23rem;
    height: 2.23rem;
    font-size: 14px;
    border-radius: 44px;
    border-style: none;
    background-color: #01365B;
    color: white;
    cursor: pointer;
    font-family: Montserrat;
    font-weight: 500;
    margin-left: 1.23rem;
}