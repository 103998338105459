
/* COLOUR FORM CLASSES */
.ncf-picker{
    height: 100px !important;
    width: 100px!important;
    margin-right: 10px;
}

.ncf-rgb-field{
    width: 80px !important;
}

.ncf-hex-field{
    width: 150px !important;
}

.ncf-field{
    width: auto !important;
    flex: 1 !important;
}


/* MATERIAL FORM CLASSES */
#material-added-flex{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    text-align: center;
}




/* GROUP FORM CLASSES */
.group-form-flex{
    display: flex;
}

.group-form-left{
    display: flex;
    width: 500px;
    flex-direction: column;
}

.group-form-left-fields{
    display: flex;
}

.group-form-left-items{
    display: flex;
    flex-direction: column;
}

.group-form-left-items-selected{
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 375px;
}

.group-form-right{
    display: flex;
    flex: 1;
    flex-direction: column;
}

.group-form-right-items{
    display: flex;
    flex-direction: column;
}

.group-form-right-items-available{
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 375px;
}

.group-form-right-search{
    display: flex;
}
.group-form-right-search-button-container{
    width: 170px;
    padding-top: calc(1.115rem + 10px);
}

.group-material-colour-item{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    position: relative;
}

.itemCheck{
    position: absolute;
    height: 100px;
    width: 100px;
    color: rgba(0,0,0,0);
    padding-top: 26px;
    padding-left: 25px;
    /* top: -70px;
    left: 30px; */
}

.itemCheck:hover{
    color: #00365a;
}