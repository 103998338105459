.company-onboarding-container{
    width: 100%;
}

.onboarding-heading
{
    margin-bottom: 2.23rem;
    font-size: 1.23rem;
}

.onboarding-subheading{
    font-size: 0.88rem;
    margin-bottom: 1.23rem;
}

.onboarding-form{
    width:100%;
    display: flex;
    justify-content: space-between;
    margin-top:0.55rem;
    padding: 0;
}


@media only screen and (max-width: 900px) {
    .onboarding-form {
        flex-direction: column !important;
    }
}


.left-items{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 ;
    margin-right: 1.23rem;
}

.right-items{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 ;
    flex-direction: column ;
}

.section-heading{
    font-weight: 700;
    margin-bottom: 0.23rem;
    color: #1f4d6e;
}

.input-style{
    -webkit-appearance:auto;
    width: 100%;
    margin-bottom: 1.23rem ;
    height: 3.3rem;
    border-style: none;
    background-color: #f4f4f4;
    border-radius: 5px;
    padding-left: 1.23rem;
    font-size: 18px;
    color: #000;
}

select{
	background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2Y0ZjRmNDt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 100% 50%;
	-moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
}


.input-style:hover{
   background-color: #f1f1f1;
}

.input-style::placeholder{
    color: rgba(1, 54, 91, 0.55);
}

.small-input-flex{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.small-input-style-left{
    width: 100%;
    margin-bottom: 1.23rem ;
    height: 3.3rem;
    border-style: none;
    background-color: #f4f4f4;
    border-radius: 5px;
    padding-left: 1.23rem;
    font-size: 18px;
    color: #000;
    margin-right: 1.23rem;
}


.small-input-style{
    margin-bottom: 1.23rem ;
    height: 3.3rem;
    border-style: none;
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 0 1.23rem ;
    font-size: 18px;
    color: #000;
    width: 100%;
}

.small-input-style::placeholder{
    color: rgba(1, 54, 91, 0.55);
    font-size: 18px;
}

.small-input-style-left::placeholder{
    color: rgba(1, 54, 91, 0.55);
    font-size: 18px;
}

.product-type-icons{
    display: flex;
    width:100%;
    justify-content:space-between;
    margin-bottom: 0.88rem;
    
}
.icon-title-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-title{
    font-size: 0.88rem;
}

.error{
    background-color: #ffddd6;
}

.form-label {
    color: #1f4d6e;
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: #00355B;
}
  
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
  /* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}
  
  /* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
  