.search-input{
    height: 2.23rem;
    width: 100%;
    border-style: none;
    background-color: #F3FAFF;
    border-radius: 44px;
    font-size: 12px;
    padding: 0.55rem;
    color: #01365B;
    border: none;
}

.search-input::placeholder{
    color: rgba(1, 54, 91, 0.88);
}

.search-input:hover{
border: solid 0.23rem rgba(12, 113, 195, 0.233);
}

.search-button{
    width: 12.3rem;
    height: 3.3rem;
    font-size: 18px;
    border-radius: 44px;
    margin-top: 1.23rem;
    border-style: none;
    background-color: #0C71C3;
    color: white;
    cursor: pointer;
    font-family: Montserrat;
    font-weight: 500;
}

