.as-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.as-section-heading-lg {
    font-size: 22px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    padding-top: 0;
}

.as-text-sm {
    padding-top: 0;
    font-size: 14px;
    margin-bottom: 0;
    padding-bottom: 0;
    color: #001624;
}

.as-status-message {
    color: #001624;
    font-weight: bold;
    padding: 20px 0;
    font-size: 20px;
}

.as-page-about {
    font-size: 20px;
}

.as-input-text {
    border: none;
    border-radius: 5px;
    background-color: #f0f0f0;
    font-size: 18px;
    padding: 5px 10px;
    margin: 0;
    margin-bottom: 20px;
}

.as-input-label {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.as-pt-15 {
    padding-top: 15px;
}

.as-search-result {
    border-radius: 10px;
    width: 200px;
    margin-right: 10px;
    margin-bottom: 20px;
    background-color: #f0f0f0;
    position: relative;
}

.as-search-result h2 {
    word-wrap: break-word;
}

.as-edit-disabled {
    cursor: not-allowed;
}

.as-edit-disabled img {
    opacity: 0.5;
}

.as-add-button {
    background-color: #01365B;
    color: white;
    font-weight: bold;
    padding: 10px 15px;
    font-size: 15px;
    text-decoration: none;
    border-radius: 10px;
    cursor: pointer;
}

.as-spacer {
    padding: 10px;
}

.as-rounded-button {
    padding: 0;
    margin: 0;;
    color: rgb(51, 51, 51);
    font-weight: bold;
    cursor: pointer;
    font-size: 13px;
}

.as-product-image {
    height: 200px;
    width: 200px;
    border-radius: 10px;
    background-position: center;
    background-size: cover;
    padding: 5px;
}