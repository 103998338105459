.company-setup-form{
    width: 100% !important;
    display: flex !important;
}

.input-flex{
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
}


.left-items{
    flex: 1 !important;
    flex-direction: column !important;
    padding-right: 1.23rem !important;
    padding-bottom: 5px;
}

.right-items{
    flex: 1 !important;
    flex-direction: column !important;
    padding-bottom: 5px;
}

.stepper-button-flex{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.input-style {
    margin-bottom: 5px;
    height: 2.8rem;
    font-size: 16px;
}

button :disabled {
    color: grey;
}
