.filter-bar-flex
{
    width: 23rem;
    height: 2.8rem;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.23rem 0;
}

.icon-flex{
    width: 7.7rem;
    display: flex;
    justify-content: space-between;
}

/* @media only screen and (max-width: 1023px) {
    .filter-bar-flex{
        width: auto;
    }
} */


